import { useEffect, useState } from 'react';

export const defaultLoaderDelay = 1000;

export const Delayed: React.FC<
  React.PropsWithChildren<{ delayMs: number }>
> = ({ children, delayMs = 2000 }) => {
  const [isVisible, setVisible] = useState(delayMs === 0);

  useEffect(() => {
    if (!delayMs) {
      return () => {};
    }

    const timeout = window.setTimeout(() => {
      setVisible(true);
    }, delayMs);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [delayMs]);

  return isVisible ? children : null;
};
